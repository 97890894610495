<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>

    <v-card flat class="pa-3 mt-2">
      <!-- <v-row> -->
      <v-col md="6" cols="12">
        &nbsp;
        <!-- <v-btn id="button_member" @click="pilihJenisMember()" color="success" class="mt-4" type="button">
        <v-icon color="#ECEFF1" id="i_button">
          {{ icons.mdiAccountCheck }}
        </v-icon>
      </v-btn> -->
        <br /><br />
      </v-col>
      <!-- </v-row> -->
      <!-- Modal -->
      <div
        id="exampleModalGroupMembers"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">MEMBERS</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="searchGroupMembers"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearchMembers()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">FIRST NAME</th>
                      <th class="text-center text-uppercase">LAST NAME NAME</th>
                      <th class="text-center text-uppercase">EMAIL</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayMembers()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_depan }}
                      </td>
                      <td class="text-center">
                        {{ data.nama_belakang }}
                      </td>
                      <td class="text-center">
                        {{ data.email }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="pilihNamaMembers(data.id_member, data.nama_depan, data.nama_belakang, data.email)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageGroupMembers == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageGroupMembers--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              v-for="pageNumber in pagesGroupMembers.slice(pageGroupMembers - 1, pageGroupMembers + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageGroupMembers ? 'active' : '']"
                              @click="pageGroupMembers = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageGroupMembers < pagesGroupMembers.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageGroupMembers++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div
        id="exampleModalPermitStay"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">PERMIT STAY</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="search"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearch()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">PERMIT STAY</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayData()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_jenispermohonan }}
                      </td>

                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihPermitStay(data.nama_jenispermohonan)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="page--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pages.slice(page - 1, page + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                              @click="page = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page < pages.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="page++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div
        id="exampleModalVisaType"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">VISA TYPE</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="searchVisaType"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearchVisaType()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">VISA TYPE</th>
                      <th class="text-center text-uppercase">DETAIL</th>
                      <th class="text-center text-uppercase">INDEX</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataVisaType()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.jenis_visa }}
                      </td>
                      <td class="text-center">
                        {{ data.detail }}
                      </td>
                      <td class="text-center">
                        {{ data.indeks }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihVisaType(data.id_statusijintinggal, data.detail)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageVisaType == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageVisaType--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pagesVisaType.slice(pageVisaType - 1, pageVisaType + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageVisaType ? 'active' : '']"
                              @click="pageVisaType = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageVisaType < pagesVisaType.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageVisaType++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div
        id="exampleModalGroupFamily"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">GROUP FAMILY</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="searchGroupFamily"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearchGroupFamily()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">LAST NAME</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataGroupFamily()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_belakang }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="pilihNamaBelakang(data.id_member, data.nama_belakang)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageGroupFamily == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageGroupFamily--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pagesGroupFamily.slice(pageGroupFamily - 1, pageGroupFamily + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageGroupFamily ? 'active' : '']"
                              @click="pageGroupFamily = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageGroupFamily < pagesGroupFamily.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageGroupFamily++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div
        id="exampleModalGroupCompany"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">GROUP COMPANY</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="searchGroupCompany"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearchGroupCompany()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">COMPANY NAME</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataGroupCompany()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_perusahaan }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="pilihNamaPerusahaan(data.id_member, data.nama_perusahaan)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageGroupCompany == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageGroupCompany--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pagesGroupCompany.slice(pageGroupCompany - 1, pageGroupCompany + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageGroupCompany ? 'active' : '']"
                              @click="pageGroupCompany = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageGroupCompany < pagesGroupCompany.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageGroupCompany++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->
      <!-- Modal -->
      <div
        id="exampleModalCountry"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">COUNTRY</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="searchNegaraAsal"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearchNegaraAsal()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA NEGARA</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataNegaraAsal()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_negara }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihNegaraAsal(data.id_negara, data.nama_negara)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageNegaraAsal--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pagesNegaraAsal.slice(pageNegaraAsal - 1, pageNegaraAsal + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageNegaraAsal ? 'active' : '']"
                              @click="pageNegaraAsal = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal < pagesNegaraAsal.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageNegaraAsal++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div
        id="exampleModalCitizenship"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">COUNTRY</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="searchNegaraAsal"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearchNegaraAsal()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA NEGARA</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataNegaraAsal()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_negara }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihKewarganegaraan(data.id_negara, data.nama_negara)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageNegaraAsal--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pagesNegaraAsal.slice(pageNegaraAsal - 1, pageNegaraAsal + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageNegaraAsal ? 'active' : '']"
                              @click="pageNegaraAsal = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal < pagesNegaraAsal.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageNegaraAsal++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE VISA </v-card-title>

        <form id="formSubmitVisa" ref="form" enctype="multipart/form-data" @submit.prevent="CreateData">
          <v-row>
            <!-- <v-col cols="12" v-if="errors.length">
            <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning"> </v-icon>
                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                </div>
              </div>
            </v-alert>
          </v-col> -->
            <span id="member_unavailable" class="d-none">
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header color="error"
                    ><h5><span style="color: #ffffff">MEMBER BARU</span></h5></v-expansion-panel-header
                  >
                  <v-expansion-panel-content
                    ><br />
                    <v-row>
                      <v-col md="6" cols="12">
                        <v-row no-gutters>
                          <v-col cols="3" md="3">
                            <v-switch label="New" @click="ubahFamily(visas.member.group_keluarga)"></v-switch>
                          </v-col>
                          <v-col cols="6" md="9">
                            <v-text-field
                              id="group_keluarga"
                              v-model="visas.member.nama_belakang"
                              label="Group Family"
                              :disabled="visas.member.group_keluarga"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalGroupFamily"
                              dense
                              outlined
                              readonly
                            ></v-text-field>
                            <text
                              id="namabelakangs"
                              label="Group Family"
                              :disabled="visas.member.group_keluarga"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalGroupFamily"
                              dense
                              outlined
                              readonly
                            ></text>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-row no-gutters>
                          <v-col cols="3" md="3">
                            <v-switch label="New" @click="ubahCompany(visas.member.group_perusahaan)"></v-switch>
                          </v-col>
                          <v-col cols="6" md="9">
                            <v-text-field
                              id="group_perusahaan"
                              v-model="visas.member.nama_perusahaan"
                              label="Group Company"
                              :disabled="visas.member.group_perusahaan"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalGroupCompany"
                              dense
                              outlined
                              readonly
                            ></v-text-field>
                            <text
                              id="perusahaans"
                              label="Group Company"
                              :disabled="visas.member.group_perusahaan"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalGroupCompany"
                              dense
                              outlined
                              readonly
                            ></text>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          v-model="visas.member.nama_depan"
                          label="First Name"
                          :required="isNewMember"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          id="nama_belakang"
                          v-model="visas.member.nama_belakang"
                          label="Last Name"
                          :required="isNewMember"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-header>Gender</v-header>
                        <v-radio-group v-model="visas.member.jenis_kelamin" row>
                          <v-radio label="Man" value="L" color="info"></v-radio>
                          <v-radio label="Woman" value="P" color="error"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-header>Marital Status</v-header>
                        <v-radio-group v-model="visas.member.status_perkawinan" col>
                          <v-radio label="Belum Menikah (Single)" value="BELUM MENIKAH" color="info"></v-radio>
                          <v-radio label="Menikah (Married)" value="MENIKAH" color="warning"></v-radio>
                          <v-radio label="Bercerai (divorce)" value="BERCERAI" color="error"></v-radio>
                        </v-radio-group>

                        <!-- <v-select
              :items="statuspernikahan"
              v-model="visas.member.status_perkawinan"
              label="Marital Status"
              dense
              outlined
            ></v-select> -->
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          id="namajenispermohonan"
                          v-model="visas.jenis_permohonan"
                          label="Permit Stay"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalPermitStay"
                          :required="isNewMember"
                          dense
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          v-model="visas.member.no_telp"
                          label="Phone Number"
                          :prepend-inner-icon="icons.mdiCellphone"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          v-model="visas.member.alamat_diindonesia"
                          label="Current Address (Indonesia)"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          v-model="visas.member.email"
                          label="Email"
                          :prepend-inner-icon="icons.mdiEmailOutline"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          v-model="visas.member.telegram_id"
                          label="Telegram ID"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          id="nama_negara"
                          v-model="visas.member.nama_negara"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalCountry"
                          label="Country of Origin"
                          dense
                          outlined
                          readonly
                        ></v-text-field>

                        <text
                          id="id_negara"
                          v-model="visas.member.negara_asal"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalCountry"
                          label="Country of Origin"
                          dense
                          outlined
                          readonly
                          type="hidden"
                        ></text>
                      </v-col>
                      <!-- <v-col md="6" cols="12">
                      <v-text-field
                        label="Visa Type"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalVisaTypeMember"
                        id="detailmember"
                        v-model="visas.detail"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                      <v-text-field
                        label="Visa Type"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalVisaTypeMember"
                        id="id_statusijintinggalmember"
                        v-model="visas.member.id_statusijintinggal"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col> -->
                      <v-col md="6" cols="12">
                        <v-text-field
                          id="nama_kewarganegaraan"
                          v-model="nama_kewarganegaraan"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalCitizenship"
                          label="Citizenship"
                          dense
                          outlined
                          readonly
                        ></v-text-field>

                        <text
                          id="kewarganegaraan"
                          v-model="visas.member.kewarganegaraan"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalCitizenship"
                          label="Citizenship"
                          dense
                          outlined
                          readonly
                          type="hidden"
                        ></text>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          id="nama_perusahaan"
                          v-model="visas.member.nama_perusahaan"
                          label="Company Name"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-text-field
                          v-model="visas.member.alamat_kantor"
                          label="Company Address"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" cols="12">
                        <v-card-text class="text-no-wrap pt-1 ps-1"> Photo Client</v-card-text>
                        <!-- <v-img :lazy-src="showPhoto()" max-height="150" max-width="150" :src="showPhoto()"></v-img> -->
                        <div class="cropper-area">
                          <div class="img-cropper">
                            <vue-cropper
                              ref="cropper"
                              :aspect-ratio="2 / 2"
                              :src="showPhotoMember()"
                              preview=".preview"
                            />
                          </div>
                        </div>

                        <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="showFileChooserMember">
                          Choose Image
                        </v-btn>
                        <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="cropImageMember">
                          Crop
                        </v-btn>

                        <input
                          id="photo"
                          ref="input"
                          style="display: none"
                          type="file"
                          :name="visas.member.photo"
                          accept="image/*"
                          @change="setImageMember"
                        />
                        <br />
                        <br />
                        <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo Client</v-card-text>
                        <section class="preview-area">
                          <!-- <p>Cover</p> -->
                          <div class="cropped-image">
                            <img
                              width="200px"
                              :src="visas.member.photo == '' ? defautlPhotoMember() : visas.member.photo"
                              alt="Cropped Image"
                            />
                          </div>
                        </section>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <br />
            </span>
            <!-- <span > -->
            <v-col id="member_available" class="d-block" md="6" cols="12">
              <v-text-field
                id="nama_member"
                v-model="nama_lengkap"
                label="Member Name"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalGroupMembers"
                dense
                outlined
                readonly
                onkeydown="return false;"
                :disabled="visas.transaksi.status_bayar == 'L'"
                name="nama_member"
              ></v-text-field>
              <text
                id="idmember"
                v-model="visas.id_member"
                label="Member Name"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalGroupMembers"
                :disabled="visas.transaksi.status_bayar == 'L'"
                dense
                outlined
                readonly
              ></text>
            </v-col>
            <!-- </span> -->

            <!-- </v-row> -->
            <!-- <v-row> -->
            <v-col id="jenis_visa_old_member" md="6" cols="12" class="d-block">
              <v-text-field
                id="namajenispermohonan"
                v-model="visas.jenis_permohonan"
                label="Permit Stay"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalPermitStay"
                :disabled="visas.transaksi.status_bayar == 'L'"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                id="detail"
                v-model="visas.detail"
                label="Visa Type"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalVisaType"
                :disabled="visas.transaksi.status_bayar == 'L'"
                dense
                outlined
                readonly
              ></v-text-field>
              <text
                id="id_statusijintinggal"
                v-model="visas.id_statusijintinggal"
                label="Visa Type"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalVisaType"
                :disabled="visas.transaksi.status_bayar == 'L'"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                v-model="visas.bandara_pelabuhan_kedatangan"
                label="Airport / Harbor of Arrival"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field v-model="visas.nomor_passport" label="Passport Number" dense outlined></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                id="nomor_aplikasi"
                v-model="visas.nomor_aplikasi"
                label="Aplication Number"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                v-model="visas.tempat_passport_dikeluarkan"
                label="Place Passport Issued"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field v-model="visas.nama_sponsor" label="Sponsor Name" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <!-- <v-header>Expired Date</v-header> -->
              <v-card-text class="text-no-wrap pt-1 ps-1"> Visa Expired Date </v-card-text>
              <datepicker
                id="tgl_kadaluarsa"
                v-model="visas.tgl_kadaluarsa"
                :format="format"
                :language="id"
                :prepend-inner-icon="icons.mdiCalendarCheck"
                name="tgl_kadaluarsa"
                placeholder="-- Visa Expired Date --"
                style="width: 100%"
                :bootstrap-styling="true"
                dense
                outlined
              >
                <!-- <div slot="beforeCalendarHeader" class="calender-header">
                <img src="/images/icon_perusahaan/icon_perusahaan_Visa_12492809092020.png" width="300px" />
              </div> -->
              </datepicker>
            </v-col>
            <v-col md="6" cols="12">
              <v-alert
                v-if="tgl_dikeluarkan_passport_valid == false && visas.tgl_dikeluarkan_passport.length == 0"
                type="error"
              >
                <strong>Passport publish date cannot be empty!</strong>
              </v-alert>
              <v-card-text class="text-no-wrap pt-1 ps-1"> Passport Publish Date </v-card-text>
              <datepicker
                id="tgl_dikeluarkan_passport"
                v-model="visas.tgl_dikeluarkan_passport"
                :format="format"
                :language="id"
                :prepend-inner-icon="icons.mdiCalendarCheck"
                name="tgl_kadaluarsa"
                placeholder="-- Passport Publish Date --"
                style="width: 100%"
                :bootstrap-styling="true"
                dense
                outlined
              >
                <!-- <div slot="beforeCalendarHeader" class="calender-header">
                <img src="/images/icon_perusahaan/icon_perusahaan_Visa_12492809092020.png" width="300px" />
              </div> -->
              </datepicker>
            </v-col>

            <v-textarea v-model="visas.alamat_sponsor" label="Sponsor Address"></v-textarea>

            <v-col md="6" cols="12">
              <v-alert
                v-if="tgl_kadaluarsa_passport_valid == false && visas.tgl_kadaluarsa_passport.length == 0"
                type="error"
              >
                <strong> Passport expired date cannot be empty!</strong>
              </v-alert>
              <v-card-text class="text-no-wrap pt-1 ps-1"> Passport Expired Date </v-card-text>
              <datepicker
                id="tgl_kadaluarsa_passport"
                v-model="visas.tgl_kadaluarsa_passport"
                :format="format"
                :language="id"
                :prepend-inner-icon="icons.mdiCalendarCheck"
                name="tgl_kadaluarsa"
                placeholder="-- Passport Expired Date --"
                style="width: 100%"
                :bootstrap-styling="true"
                dense
                outlined
              >
                <!-- <div slot="beforeCalendarHeader" class="calender-header">
                <img src="/images/icon_perusahaan/icon_perusahaan_Visa_12492809092020.png" width="300px" />
              </div> -->
              </datepicker>
            </v-col>

            <v-col md="6" cols="12">
              <v-alert
                v-if="tgl_kadaluarsa_passport_valid == false && visas.tgl_kadaluarsa_passport.length == 0"
                type="error"
              >
                <strong> Passport expired date cannot be empty!</strong>
              </v-alert>
              <v-card-text class="text-no-wrap pt-1 ps-1"> Arrival Date </v-card-text>
              <datepicker
                v-model="visas.tgl_kedatangan"
                :format="format"
                :language="id"
                :prepend-inner-icon="icons.mdiCalendarCheck"
                name="tgl_kadaluarsa"
                placeholder="-- Arrival Date --"
                style="width: 100%"
                :bootstrap-styling="true"
                dense
                outlined
              >
                <!-- <div slot="beforeCalendarHeader" class="calender-header">
                <img src="/images/icon_perusahaan/icon_perusahaan_Visa_12492809092020.png" width="300px" />
              </div> -->
              </datepicker>
            </v-col>

            <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo Visa </v-card-text>

              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper ref="croppervisa" :aspect-ratio="2 / 2" :src="showPhotoVisa()" preview=".preview" />
                </div>
              </div>

              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="showFileChooserVisa">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="cropImageVisa"> Crop </v-btn>

              <input
                id="photo_visa"
                ref="inputvisa"
                style="display: none"
                type="file"
                :name="visas.photo_visa"
                accept="image/*"
                @change="setImageVisa"
              />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo Visa </v-card-text>
              <section class="preview-area">
                <div class="cropped-image">
                  <img width="200px" :src="updatePhotoVisa()" />
                  <!-- <img
                  width="200px"
                  :src="visas.photo_visa != null ? defautlPhotoVisa() : visas.photo_visa"
                  alt="Cropped Image"
                /> -->
                </div>
              </section>
            </v-col>
            <hr />
            <v-checkbox
              v-model="visas.cek_transaksi"
              label="Checklist if there is no transaction! (Payment Gateway Transaction)"
            ></v-checkbox>

            <v-row v-show="visas.cek_transaksi == false" class="mb-6" no-gutters>
              <v-col sm="5" md="6">
                <!-- Kiri -->
                <v-card-text class="text-no-wrap pt-1 ps-1">
                  <strong>(IDR)</strong>
                  <div v-for="(by, index) in biaya" :key="index">
                    <v-checkbox
                      :id="by.id_biaya"
                      v-model="by.checked"
                      :required="visas.cek_transaksi == false && visas.biaya.length == 0"
                      :label="by.jenis_biaya"
                      color="success"
                      hide-details
                      @change="checkboxClick(by.id_biaya, by.jenis_biaya, by.harga, by.checked)"
                    ></v-checkbox>
                  </div>
                </v-card-text>
              </v-col>
              <!-- Kanan -->
              <v-col sm="5" offset-sm="2" md="6" offset-md="0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">VISA TYPE</th>
                        <th class="text-left">PRICE</th>
                      </tr>
                    </thead>
                    <tbody v-if="endprice_biaya != 0">
                      <tr v-for="(hb, index) in hitung_biaya" :key="index">
                        <td>{{ hb.jenis_biaya }}</td>
                        <td>{{ hb.harga | currency }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>TOTAL COST</b>
                        </td>
                        <td>
                          <b>{{ endprice_biaya | currency }}</b>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td class="text-center" colspan="2">No Fee!</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row v-show="visas.cek_transaksi == false" class="mb-6" no-gutters>
              <v-col sm="5" md="6">
                <!-- Kiri -->
                <v-card-text class="text-no-wrap pt-1 ps-1">
                  <strong>(USD)</strong>
                  <div v-for="(by, index) in biaya_dollar" :key="index">
                    <v-checkbox
                      :id="by.id_biaya_dollar"
                      :label="by.jenis_biaya"
                      v-model="by.checked"
                      color="success"
                      hide-details
                      @click="checkboxClickDollar(by.id_biaya_dollar, by.jenis_biaya, by.harga, by.checked)"
                    ></v-checkbox>
                  </div>
                </v-card-text>
              </v-col>
              <!-- Kanan -->
              <v-col sm="5" offset-sm="2" md="6" offset-md="0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">VISA TYPE</th>
                        <th class="text-left">PRICE</th>
                      </tr>
                    </thead>
                    <tbody v-if="endprice_biaya_dollar != 0">
                      <tr v-for="(hbd, index) in hitung_biaya_dollar" :key="index">
                        <td>{{ hbd.jenis_biaya }}</td>
                        <td>{{ hbd.harga | dollar_currency }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>TOTAL COST</b>
                        </td>
                        <td>
                          <b>{{ endprice_biaya_dollar | dollar_currency }}</b>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td class="text-center" colspan="2">No Fee!</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col md="12" cols="12">
                <br />
                <br />
                <hr />
              </v-col>
              <v-col md="6" cols="12">
                <v-header>Pay Type</v-header>
                <v-radio-group v-model="visas.transaksi.tipe_bayar" row>
                  <v-radio id="tunai" label="Cash" value="tunai" color="info"></v-radio>
                  <v-radio id="transfer" label="Transfer" value="transfer" color="error"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col v-if="visas.transaksi.tipe_bayar.toLowerCase() != 'tunai'" md="6" cols="12">
                <v-card-text class="text-no-wrap pt-1 ps-1"> Evidence of Transfer </v-card-text>
                <div class="cropper-area">
                  <div class="img-cropper">
                    <img :src="showBuktipembayaran()" width="200px" />
                  </div>
                </div>

                <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="showFileChooserBuktiPembayaran">
                  Choose Image
                </v-btn>

                <input
                  id="photo_bukti_pembayaran"
                  ref="inputbuktipembayaran"
                  style="display: none"
                  type="file"
                  :name="visas.transaksi.bukti_pembayaran"
                  accept="image/*"
                  @change="setImageBuktiPembayaran"
                />
              </v-col>
            </v-row>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Update </v-btn>

              <router-link :to="{ name: 'visas-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  mdiCheckBold,
  mdiCalendarMonth,
  mdiCalendarCheck,
  mdiAccountPlus,
  mdiAccountCheck,
  mdiEmailOutline,
  mdiCellphone,
  mdiCamera,
  mdiSlashForward,
} from '@mdi/js'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import Datepicker from 'vuejs-datepicker'
import { id } from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    VueCropper,
    Datepicker,
    mdiSlashForward,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Visas',
          disabled: false,
          href: '/admin/visas',
        },
        {
          text: 'Create Visa',
          disabled: true,
        },
      ],
      icons: {
        mdiCheckBold,
        mdiCalendarMonth,
        mdiCalendarCheck,
        mdiAccountPlus,
        mdiAccountCheck,
        mdiEmailOutline,
        mdiCellphone,
        mdiCamera,
        mdiSlashForward,
      },
      rules: [value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!'],
      statuspernikahan: ['Belum Menikah', 'Menikah', 'Bercerai'],
      nama_kewarganegaraan: '',

      // dataNB: [],
      visas: {
        id_visa: this.$route.params.id_visa,
        id_member: this.$route.params.id_member,
        jenis_permohonan: '',

        // tgl_permohonan: '',
        tgl_kadaluarsa: '',
        nomor_aplikasi: '',
        nomor_passport: '',
        tgl_dikeluarkan_passport: '',
        tgl_kadaluarsa_passport: '',
        tempat_passport_dikeluarkan: '',

        // alasan_permohonan_visa: '',
        nama_sponsor: '',
        alamat_sponsor: '',
        bandara_pelabuhan_kedatangan: '',
        tgl_kedatangan: '',
        id_statusijintinggal: '',

        // aktif: '',
        photo_visa: '',
        jenis_visa: 'baru',

        biaya: [],
        biaya_dollar: [],
        cek_transaksi: false,

        member: {
          id_statusijintinggal: '',
          nama_depan: '',
          nama_belakang: '',
          negara_asal: '',
          alamat_diindonesia: '',
          email: '',
          group_keluarga: false,
          group_perusahaan: false,
          no_telp: '',
          telegram_id: '',
          kewarganegaraan: '',
          status_perkawinan: 'BELUM MENIKAH',
          jenis_kelamin: 'L',
          nama_perusahaan: '',
          alamat_kantor: '',
          photo: '',
        },

        transaksi: {
          id_transaksi: this.$route.params.id_transaksi,
          id_pegawai: '',
          tipe_bayar: 'tunai',
          jumlah_bayar: 0,
          jumlah_bayar_dollar: 0,
          status_bayar: 'BB',
          bukti_pembayaran: '',
        },
      },

      errors: [],
      members: [],
      permitstay: [],
      visatype: [],

      id,
      format: 'd MMMM yyyy',
      tgl_dikeluarkan_passport_valid: true,
      tgl_kadaluarsa_passport_valid: true,
      compareDates_valid: true,

      biaya: [],
      biaya_member: [],
      biaya_dollar_member: [],
      biaya_dollar: [],
      hitung_biaya: [],
      hitung_biaya_dollar: [],

      endprice_biaya: 0,
      endprice_biaya_dollar: 0,

      nama_lengkap: '',

      // cek_transaksi: false,

      // biaya: [],
      // biaya_dollar: [],

      isNewMember: false,
      negaraasal: [],
      namabelakangs: [],
      perusahaans: [],

      pageGroupMembers: 1,
      perPageGroupMembers: 10,
      pagesGroupMembers: [],
      searchGroupMembers: '',
      searchResultGroupMembers: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],

      pageVisaType: 1,
      perPageVisaType: 10,
      pagesVisaType: [],
      searchVisaType: '',
      searchResultVisaType: [],

      pageGroupFamily: 1,
      perPageGroupFamily: 10,
      pagesGroupFamily: [],
      searchGroupFamily: '',
      searchResultGroupFamily: [],

      pageGroupCompany: 1,
      perPageGroupCompany: 10,
      pagesGroupCompany: [],
      searchGroupCompany: '',
      searchResultGroupCompany: [],

      pageNegaraAsal: 1,
      perPageNegaraAsal: 10,
      pagesNegaraAsal: [],
      searchNegaraAsal: '',
      searchResultNegaraAsal: [],
    }
  },
  watch: {
    detail() {
      this.pages = []
      this.setPages()
    },

    visatype() {
      this.pagesVisaType = []
      this.setPagesDataVisaType()
    },

    members() {
      this.pagesGroupMembers = []
      this.setPagesDataGroupMembers()
    },

    namabelakangs() {
      this.pagesGroupFamily = []
      this.setPagesDataGroupFamily()
    },

    perusahaans() {
      this.pagesGroupCompany = []
      this.setPagesDataGroupCompany()
    },
  },
  mounted() {
    this.$isLoading(true)
    this.start().then(() => {
      this.$isLoading(false)
    })

    //   setTimeout(() => {
    //     // this.getNamaBelakang()

    //     this.$isLoading(false)
    //   }, 2000)
  },

  // mounted() {
  //   this.$isLoading(true)
  //   this.getNamaMembers()
  //   this.createApplicationNumber()
  //   this.getBiaya()
  //   this.getBiayaDollar()
  //   this.getPermitStay()
  //   this.getVisaType()
  //   this.getNamaBelakang()
  //   this.getNamaPerusahaan()
  //   this.getNegaraAsal()
  //   this.getUserLogin()
  //   setTimeout(() => {
  //     // this.getNamaBelakang()

  //     this.$isLoading(false)
  //   }, 2000)
  // },
  methods: {
    async start() {
      await this.createApplicationNumber()
      await this.getBiaya()
      await this.getBiayaDollar()
      await this.getPermitStay()

      await this.getNamaBelakang()
      await this.getNamaPerusahaan()
      await this.getNegaraAsal()

      await this.getTransaksiById()
      await this.getUserLogin()
      await this.getMemberVisa()
      await this.getNamaMembers()
      await this.getVisaType()

      await this.getDetailTransaksiMember()
    },

    async getDetailTransaksiMember() {
      const uri = `${process.env.VUE_APP_ROOT_API}/api/transaksis/getdetailtransaksi/${this.$route.params.id_member}/${this.$route.params.id_transaksi}`
      const response = await this.axios.get(uri)

      if (response.data.biaya.length === 0 && response.data.biaya_dollar.length === 0) {
        this.visas.cek_transaksi = true

        return
      }

      this.biaya_member = response.data.biaya
      this.biaya_dollar_member = response.data.biaya_dollar
      this.visas.biaya = []
      this.visas.biaya_dollar = []
      this.hitung_biaya = []
      this.hitung_biaya_dollar = []
      this.endprice_biaya = 0
      this.endprice_biaya_dollar = 0

      this.biaya = this.biaya.map(b => {
        const biaya = b
        biaya.checked = this.biaya_member.some(bm => bm.id_biaya === biaya.id_biaya)
        this.checkboxClick(biaya.id_biaya, biaya.jenis_biaya, biaya.harga, biaya.checked)

        return biaya
      })

      this.biaya_dollar = this.biaya_dollar.map(bd => {
        const biayaDollar = bd
        biayaDollar.checked = this.biaya_dollar_member.some(bdm => bdm.id_biaya_dollar === biayaDollar.id_biaya_dollar)
        this.checkboxClickDollar(
          biayaDollar.id_biaya_dollar,
          biayaDollar.jenis_biaya,
          biayaDollar.harga,
          biayaDollar.checked,
        )

        return biayaDollar
      })
    },

    async getTransaksiById() {
      // let uri = '/api/admin/show_transaksi/' + this.visa.transaksi.id_transaksi
      const uriTransaksiId = `${process.env.VUE_APP_ROOT_API}/api/transaksis/${this.$route.params.id_transaksi}/transaksibyid`
      const response = await this.axios.get(uriTransaksiId)

      // console.log('OUTPUT :' + response.data.transaksibyid.tipe_bayar)
      this.visas.transaksi.status_bayar = response.data.transaksibyid.status_bayar
      this.visas.transaksi.tipe_bayar = response.data.transaksibyid.tipe_bayar
    },
    async getUserLogin() {
      // state token
      localStorage.getItem('token')

      const uri_user_login = `${process.env.VUE_APP_ROOT_API}/api/auth`
      this.axios.get(uri_user_login).then(response => {
        this.visas.transaksi.id_pegawai = response.data.auth
      })
    },

    async getMemberVisa() {
      // state token
      localStorage.getItem('token')
      const uri = `${process.env.VUE_APP_ROOT_API}/api/transaksis/member`
      const response = await this.axios.get(uri)
      const data = response.data.member.find(m => m.id_member === this.$route.params.id_member)
      const data_1 = data
      await this.pilihNamaMembers(data_1.id_member, data_1.nama_depan, data_1.nama_belakang, data_1.email)
    },

    async pilihNamaMembers(id_member, nama_depan, nama_belakang, email) {
      // state token
      localStorage.getItem('token')

      // this.resetAll()
      this.nama_lengkap = `${nama_depan} (${nama_belakang})`
      this.visas.id_member = id_member
      this.visas.member.email = email
      const uri =
        `${process.env.VUE_APP_ROOT_API}/api/transaksis/membervisa/${id_member}/` + `${this.$route.params.id_visa}`
      this.axios.get(uri).then(response => {
        if (response.data.visas) {
          this.$isLoading(true)
          setTimeout(() => {
            if (Object.keys(response.data.visas).includes('status_ijin_tinggal')) {
              this.PilihVisaType(
                response.data.visas.status_ijin_tinggal.id_statusijintinggal,
                response.data.visas.status_ijin_tinggal.detail,
              )
            }
            Object.assign(this.visas, response.data.visas)
            this.$isLoading(false)
          }, 500)
        }
      })
    },

    async getVisaType() {
      // state token
      localStorage.getItem('token')

      const uriVisaType = `${process.env.VUE_APP_ROOT_API}/api/statusijintinggals`

      return this.axios.get(uriVisaType).then(response => {
        this.visatype = response.data.statusijintinggal
      })
    },

    ubahFamily(group_keluarga) {
      this.visas.member.group_keluarga = !this.visas.member.group_keluarga
    },

    ubahCompany(group_perusahaan) {
      this.visas.member.group_perusahaan = !this.visas.member.group_perusahaan
    },

    resetAll() {
      this.visas.id_visa = ''
      this.visas.id_member = ''
      this.visas.jenis_permohonan = ''
      this.visas.tgl_kadaluarsa = ''
      this.visas.nomor_passport = ''
      this.visas.tgl_dikeluarkan_passport = ''
      this.visas.tgl_kadaluarsa_passport = ''
      this.visas.tempat_passport_dikeluarkan = ''
      this.visas.nama_sponsor = ''
      this.visas.alamat_sponsor = ''
      this.visas.bandara_pelabuhan_kedatangan = ''
      this.visas.tgl_kedatangan = ''
      this.visas.id_statusijintinggal = ''
      this.visas.aktif = ''
      this.visas.photo_visa = ''
      this.visas.member.id_statusijintinggal = ''
      this.visas.member.nama_depan = ''
      this.visas.member.nama_belakang = ''
      this.visas.member.negara_asal = ''
      this.visas.member.alamat_diindonesia = ''
      this.visas.member.email = ''
      this.visas.member.group_keluarga = ''
      this.visas.member.group_perusahaan = ''
      this.visas.member.no_telp = ''
      this.visas.member.telegram_id = ''
      this.visas.member.kewarganegaraan = ''
      this.visas.member.status_perkawinan = 'BELUM MENIKAH'
      this.visas.member.jenis_kelamin = 'L'
      this.visas.member.nama_perusahaan = ''
      this.visas.member.alamat_kantor = ''
      this.visas.member.photo = ''

      this.nama_lengkap = ''
    },

    hitungEndpriceBiaya() {
      if (this.hitung_biaya.length > 0) {
        this.endprice_biaya = this.hitung_biaya
          .map(hb => hb.harga)
          .reduce((prev, curr) => parseInt(prev) + parseInt(curr))

        this.visas.transaksi.jumlah_bayar = this.endprice_biaya

        return
      }

      this.endprice_biaya = 0
    },

    hitungEndpriceBiayaDollar() {
      if (this.hitung_biaya_dollar.length > 0) {
        this.endprice_biaya_dollar = this.hitung_biaya_dollar
          .map(hbd => hbd.harga)
          .reduce((prev, curr) => parseInt(prev) + parseInt(curr))

        this.visas.transaksi.jumlah_bayar_dollar = this.endprice_biaya_dollar

        return
      }

      this.endprice_biaya_dollar = 0
    },

    checkboxClickDollar(id_biaya_dollar, jenis_biaya, harga, checked) {
      const data_biaya_dollar = {
        id_biaya_dollar,
        jenis_biaya,
        harga: parseInt(harga),
      }

      const duplicate = this.visas.biaya_dollar.some(bd => bd === id_biaya_dollar)

      if (!duplicate) {
        this.visas.biaya_dollar.push(id_biaya_dollar)
        this.hitung_biaya_dollar.push(data_biaya_dollar)
      }

      if (!checked) {
        this.visas.biaya_dollar = this.visas.biaya_dollar.filter(bd => bd !== id_biaya_dollar)
        this.hitung_biaya_dollar = this.hitung_biaya_dollar.filter(hbd => hbd.id_biaya_dollar !== id_biaya_dollar)
      }

      this.hitungEndpriceBiayaDollar()
    },

    checkboxClick(id_biaya, jenis_biaya, harga, checked) {
      const data_biaya = {
        id_biaya,
        jenis_biaya,
        harga: parseInt(harga),
      }

      const duplicate = this.visas.biaya.some(b => b === id_biaya)

      if (!duplicate) {
        this.visas.biaya.push(id_biaya)
        this.hitung_biaya.push(data_biaya)
      }

      if (!checked) {
        this.visas.biaya = this.visas.biaya.filter(b => b !== id_biaya)
        this.hitung_biaya = this.hitung_biaya.filter(hb => hb.id_biaya !== id_biaya)
      }

      this.hitungEndpriceBiaya()
    },

    async createApplicationNumber() {
      // state token
      localStorage.getItem('token')

      const uriToken = `${process.env.VUE_APP_ROOT_API}/api/visas/token`
      this.axios.get(uriToken).then(response => {
        this.visas.nomor_aplikasi = response.data.token
      })
    },

    async getNamaMembers() {
      // state token
      localStorage.getItem('token')

      const uriNamaPerusahaan = `${process.env.VUE_APP_ROOT_API}/api/members`

      return this.axios.get(uriNamaPerusahaan).then(response => {
        this.members = response.data.member
      })
    },

    async getPermitStay() {
      // state token
      localStorage.getItem('token')

      const url = `${process.env.VUE_APP_ROOT_API}/api/jenispermohonans`

      return this.axios.get(url).then(response => {
        this.permitstay = response.data.jenispermohonan
      })
    },

    async getNamaBelakang() {
      // state token
      localStorage.getItem('token')

      const uriNamaBelakang = `${process.env.VUE_APP_ROOT_API}/api/members/namabelakangs`

      return this.axios.get(uriNamaBelakang).then(response => {
        this.namabelakangs = response.data.namabelakang
        this.nama_belakang_suggestion = this.namabelakangs.map(({ nama_belakang }) => nama_belakang)
      })
    },

    async getNamaPerusahaan() {
      // state token
      localStorage.getItem('token')

      const uriNamaPerusahaan = `${process.env.VUE_APP_ROOT_API}/api/members/perusahaans`

      return this.axios.get(uriNamaPerusahaan).then(response => {
        this.perusahaans = response.data.perusahaan
      })
    },

    async getNegaraAsal() {
      // state token
      localStorage.getItem('token')

      const uriNegara = `${process.env.VUE_APP_ROOT_API}/api/negaras`

      return this.axios.get(uriNegara).then(response => {
        this.negaraasal = response.data.negaras
      })
    },

    async getBiaya() {
      // state token
      localStorage.getItem('token')

      const uri_biaya = `${process.env.VUE_APP_ROOT_API}/api/biayas`
      this.axios.get(uri_biaya).then(response => {
        this.biaya = response.data.biaya
      })
    },

    async getBiayaDollar() {
      // state token
      localStorage.getItem('token')

      const uri_biaya_dollar = `${process.env.VUE_APP_ROOT_API}/api/biayadollars`
      this.axios.get(uri_biaya_dollar).then(response => {
        this.biaya_dollar = response.data.biayadollar
      })
    },

    defautlPhotoMember() {
      const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/member/no-image.png`

      return imageDefault
    },

    showPhotoMember() {
      if (this.visas.member.photo.length == 0) {
        const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/member/no-image.png`

        return imageDefault
      }
      const imageClientMember = `${process.env.VUE_APP_ROOT_API}/${this.visas.member.photo}`

      return imageClientMember
    },

    showBuktipembayaran() {
      if (this.visas.transaksi.bukti_pembayaran == 0) {
        return `${process.env.VUE_APP_ROOT_API}/images/bukti_pembayaran/no-images.png`
      }

      return this.visas.transaksi.bukti_pembayaran.length > 100
        ? this.visas.transaksi.bukti_pembayaran
        : `${process.env.VUE_APP_ROOT_API}/${this.visas.transaksi.bukti_pembayaran}`
    },

    setImageMember(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')

        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.visas.member.photo = event.target.result

          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },

    cropImageMember() {
      // get image data for post processing, e.g. upload or setting image src
      this.visas.member.photo = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },

    showFileChooserMember() {
      this.$refs.input.click()
    },

    setImageBuktiPembayaran(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')

        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.visas.transaksi.bukti_pembayaran = event.target.result

          // rebuild cropperjs with the updated source
          // this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },

    showFileChooserBuktiPembayaran() {
      this.$refs.inputbuktipembayaran.click()
    },

    updatePhotoVisa() {
      if (this.visas.photo_visa == null || this.visas.photo_visa == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/pegawai/no-image.png`
        return imageDefault
      }

      let img = this.visas.photo_visa

      if (img.length > 100) {
        return this.visas.photo_visa
      } else {
        let imageClients =
          process.env.VUE_APP_ROOT_API + `/images/visa/${this.visas.id_member}/${this.visas.photo_visa}`
        return imageClients
      }
    },

    defautlPhotoVisa() {
      // state token
      localStorage.getItem('token')

      if (this.visas.photo_visa == '') {
        const imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/no-image.png`

        return imageDefault
      }
      const imageClient = process.env.VUE_APP_ROOT_API + `/images/visa/${this.visas.id_member}/${this.visas.photo_visa}`
      console.log(imageClient)
      return imageClient
    },

    showPhotoVisa() {
      // state token
      localStorage.getItem('token')

      if (this.visas.photo_visa.length == 0) {
        const imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/no-image.png`

        return imageDefault
      }
      const imageClient = process.env.VUE_APP_ROOT_API + `/images/visa/${this.visas.id_member}/${this.visas.photo_visa}`
      // console.log(imageClient)
      return imageClient
    },

    setImageVisa(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')

        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.visas.photo_visa = event.target.result

          this.$refs.croppervisa.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImageVisa() {
      this.visas.photo_visa = this.$refs.croppervisa.getCroppedCanvas().toDataURL()
    },
    showFileChooserVisa() {
      this.$refs.inputvisa.click()
    },

    checkGroupKeluarga(input) {
      const isGroupKeluargaExists = this.namabelakangs.some(v => v.nama_belakang.toLowerCase() === input.toLowerCase())

      this.visas.member.grup_keluarga = isGroupKeluargaExists
    },

    async checkEmail() {
      const uriCheckEmail = `${process.env.VUE_APP_ROOT_API}/api/members/checkemail`

      return await this.axios.post(uriCheckEmail, {
        email: this.visas.member.email,
      })
    },

    async checkNotelp() {
      const uriPhoneNumber = `${process.env.VUE_APP_ROOT_API}/api/members/checknotelp`

      return await this.axios.post(uriPhoneNumber, {
        no_telp: this.visas.member.no_telp,
      })
    },

    // async checkVisa() {
    //   let uriCheckVisa = process.env.VUE_APP_ROOT_API + '/api/visas/checkvisa'
    //   return await this.axios.post(uriCheckVisa, this.visas)
    // },

    checkGroupPerusahaan(input) {
      const isGroupPerusahaanExists = this.perusahaans.some(
        v =>
          v.nama_perusahaan.trim().toLowerCase().replace(/\s+/g, '') === input.trim().toLowerCase().replace(/\s+/g, ''),
      )

      this.visas.member.group_perusahaan = isGroupPerusahaanExists
    },

    compareDates() {
      const date_1 = new Date(this.visas.tgl_dikeluarkan_passport)
      const date_2 = new Date(this.visas.tgl_kadaluarsa_passport)

      return date_1.getTime() >= date_2.getTime()
    },

    async CreateData() {
      // Validate before POST Request
      if (this.visas.cek_transaksi) {
        this.visas.biaya = []
        this.visas.biaya_dollar = []
        this.hitung_biaya = []
        this.hitung_biaya_dollar = []
        this.visas.transaksi.jumlah_bayar = 0
        this.endprice_biaya = 0
        this.endprice_biaya_dollar = 0
        this.visas.transaksi.jumlah_bayar_dollar = 0
        this.biaya.forEach(b => {
          document.getElementById(b.id_biaya).checked = false
        })
        this.biaya_dollar.forEach(bd => {
          document.getElementById(bd.id_biaya_dollar).checked = false
        })
      } else {
        this.visas.transaksi.tipe_bayar = 'tunai'
      }

      this.$isLoading(true)
      if (this.visas.tgl_kadaluarsa_passport.length == 0) {
        this.$isLoading(false)
        this.tgl_kadaluarsa_passport_valid = false
        form['tgl_kadaluarsa_passport'].focus()
        return
      }

      if (this.compareDates()) {
        this.$isLoading(false)
        this.compareDates_valid = false
        form['tgl_kadaluarsa_passport'].focus()
        return
      }

      this.tgl_kadaluarsa_passport_valid = true
      this.visas.tgl_dikeluarkan_passport_valid = true
      this.compareDates_valid = true

      try {
        if (this.compareDates(this.visas.tgl_dikeluarkan_passport, this.visas.tgl_kadaluarsa_passport)) {
          this.$isLoading(false)
          this.$swal.fire({
            title: 'Gagal',
            text: 'Tanggal Kadaluarsa Passport tidak valid!',
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          })
        } else {
          try {
            if ((this.visas.cek_transaksi && this.visas.member.email == undefined) || this.visas.member.email == '') {
              this.$isLoading(false)
              return this.$swal.fire({
                title: 'Peringatan',
                text: 'E-mail diperlukan untuk menggunakan payment gateway',
                icon: 'warning',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 2000,
              })
            }
            this.visas.id_visa = this.$route.params.id_visa

            const response = await this.axios.post(process.env.VUE_APP_ROOT_API + `/api/visas/update`, this.visas)
            // console.log(response.data)
            this.$isLoading(false)
            this.$swal
              .fire({
                title: 'Success',
                text: 'Visa updated successfully',
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1000,
              })
              .then(v => {
                if (v.isDismissed) {
                  if (this.visas.transaksi.status_bayar == 'BB') {
                    // if (this.visas.cek_transaksi) {
                    //   return this.$router.push({
                    //     name: 'visas-transaksi',
                    //     params: {
                    //       id_member: this.visas.id_member,
                    //       id_visa: this.visas.id_visa,
                    //     },
                    //   })
                    // }

                    return this.$router.push({
                      name: 'visas-pembayaran',
                      params: { id_visa: this.visas.id_visa },
                    })
                  }
                  return this.$router.push({
                    name: 'visas-index',
                  })
                }
              })
          } catch (e) {
            this.$isLoading(false)
            this.$swal.fire({
              title: 'Error',
              text: 'Gagal update visa',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          }
        }
      } catch (error) {
        this.$isLoading(false)
        this.$swal.fire({
          title: 'Error',
          text: error,
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000,
        })
      }
    },

    doSearch() {
      const go = this.permitstay.filter(item =>
        this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_jenispermohonan.toLowerCase().includes(v)),
      )
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.permitstay)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.permitstay.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(permitstay) {
      const { page } = this
      const { perPage } = this
      const from = page * perPage - perPage
      const to = page * perPage

      return permitstay.slice(from, to)
    },

    doSearchVisaType() {
      const go = this.visatype.filter(item =>
        this.searchVisaType
          .toLowerCase()
          .split(' ')
          .every(v => item.detail.toLowerCase().includes(v)),
      )
      this.searchResultVisaType = go
      this.pagesVisaType = []
      this.setPagesDataVisaType()
    },
    displayDataVisaType() {
      let paginateVisaType

      if (this.searchVisaType == '') {
        paginateVisaType = this.paginateVisaType(this.visatype)
      } else {
        paginateVisaType = this.paginateVisaType(this.searchResultVisaType)
      }

      return paginateVisaType
    },
    setPagesDataVisaType() {
      let numberOfPages

      if (this.searchVisaType == '') {
        numberOfPages = Math.ceil(this.visatype.length / this.perPageVisaType)
      } else {
        numberOfPages = Math.ceil(this.searchResultVisaType.length / this.perPageVisaType)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesVisaType.push(index)
      }
    },
    paginateVisaType(visatype) {
      const { pageVisaType } = this
      const { perPageVisaType } = this
      const from = pageVisaType * perPageVisaType - perPageVisaType
      const to = pageVisaType * perPageVisaType

      return visatype.slice(from, to)
    },

    doSearchMembers() {
      const go = this.members.filter(item =>
        this.searchGroupMembers
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_depan.toLowerCase().includes(v)),
      )
      this.searchResultGroupMembers = go
      this.pagesGroupMembers = []
      this.setPagesDataGroupMembers()
    },
    displayMembers() {
      let paginateGroupMembers

      if (this.searchGroupMembers == '') {
        paginateGroupMembers = this.paginateGroupMembers(this.members)
      } else {
        paginateGroupMembers = this.paginateGroupMembers(this.searchResultGroupMembers)
      }

      return paginateGroupMembers
    },
    setPagesDataGroupMembers() {
      let numberOfPages

      if (this.searchGroupMembers == '') {
        numberOfPages = Math.ceil(this.members.length / this.perPageGroupMembers)
      } else {
        numberOfPages = Math.ceil(this.searchResultGroupMembers.length / this.perPageGroupMembers)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesGroupMembers.push(index)
      }
    },

    paginateGroupMembers(members) {
      const { pageGroupMembers } = this
      const { perPageGroupMembers } = this
      const from = pageGroupMembers * perPageGroupMembers - perPageGroupMembers
      const to = pageGroupMembers * perPageGroupMembers

      return members.slice(from, to)
    },

    doSearchGroupFamily() {
      const go = this.namabelakangs.filter(item =>
        this.searchGroupFamily
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_belakang.toLowerCase().includes(v)),
      )
      this.searchResultGroupFamily = go
      this.pagesGroupFamily = []
      this.setPagesDataGroupFamily()
    },
    displayDataGroupFamily() {
      let paginateGroupFamily

      if (this.searchGroupFamily == '') {
        paginateGroupFamily = this.paginateGroupFamily(this.namabelakangs)
      } else {
        paginateGroupFamily = this.paginateGroupFamily(this.searchResultGroupFamily)
      }

      return paginateGroupFamily
    },
    setPagesDataGroupFamily() {
      let numberOfPages

      if (this.searchGroupFamily == '') {
        numberOfPages = Math.ceil(this.namabelakangs.length / this.perPageGroupFamily)
      } else {
        numberOfPages = Math.ceil(this.searchResultGroupFamily.length / this.perPageGroupFamily)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesGroupFamily.push(index)
      }
    },

    paginateGroupFamily(namabelakangs) {
      const { pageGroupFamily } = this
      const { perPageGroupFamily } = this
      const from = pageGroupFamily * perPageGroupFamily - perPageGroupFamily
      const to = pageGroupFamily * perPageGroupFamily

      return namabelakangs.slice(from, to)
    },

    displayDataGroupCompany() {
      let paginateGroupCompany

      if (this.searchGroupCompany == '') {
        paginateGroupCompany = this.paginateGroupCompany(this.perusahaans)
      } else {
        paginateGroupCompany = this.paginateGroupCompany(this.searchResultGroupCompany)
      }

      return paginateGroupCompany
    },
    setPagesDataGroupCompany() {
      let numberOfPages

      if (this.searchGroupCompany == '') {
        numberOfPages = Math.ceil(this.perusahaans.length / this.perPageGroupCompany)
      } else {
        numberOfPages = Math.ceil(this.searchResultGroupCompany.length / this.perPageGroupCompany)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesGroupCompany.push(index)
      }
    },

    paginateGroupCompany(perusahaans) {
      const { pageGroupCompany } = this
      const { perPageGroupCompany } = this
      const from = pageGroupCompany * perPageGroupCompany - perPageGroupCompany
      const to = pageGroupCompany * perPageGroupCompany

      return perusahaans.slice(from, to)
    },

    doSearchNegaraAsal() {
      const go = this.negaraasal.filter(item =>
        this.searchNegaraAsal
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_negara.toLowerCase().includes(v)),
      )
      this.searchResultNegaraAsal = go
      this.pagesNegaraAsal = []
      this.setPagesDataNegaraAsal()
    },
    displayDataNegaraAsal() {
      let paginateNegaraAsal

      if (this.searchNegaraAsal == '') {
        paginateNegaraAsal = this.paginateNegaraAsal(this.negaraasal)
      } else {
        paginateNegaraAsal = this.paginateNegaraAsal(this.searchResultNegaraAsal)
      }

      return paginateNegaraAsal
    },
    setPagesDataNegaraAsal() {
      let numberOfPages

      if (this.searchNegaraAsal == '') {
        numberOfPages = Math.ceil(this.negaraasal.length / this.perPageNegaraAsal)
      } else {
        numberOfPages = Math.ceil(this.searchResultNegaraAsal.length / this.perPageNegaraAsal)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesNegaraAsal.push(index)
      }
    },
    paginateNegaraAsal(negaraasal) {
      const { pageNegaraAsal } = this
      const { perPageNegaraAsal } = this
      const from = pageNegaraAsal * perPageNegaraAsal - perPageNegaraAsal
      const to = pageNegaraAsal * perPageNegaraAsal

      return negaraasal.slice(from, to)
    },

    PilihPermitStay(nama_jenispermohonan) {
      // document.querySelector('#idjenispermohonan').setAttribute('value', id_jenispermohonan)
      document.querySelector('#namajenispermohonan').setAttribute('value', nama_jenispermohonan)

      // this.visas.id_jenispermohonan = id_jenispermohonan
      this.visas.jenis_permohonan = nama_jenispermohonan
    },

    PilihVisaType(id_statusijintinggal, detail) {
      // console.log('detail:' + detail)
      // document.querySelector('#id_statusijintinggal').setAttribute('value', id_statusijintinggal)
      // document.querySelector('#detail').setAttribute('value', detail)
      this.visas.id_statusijintinggal = id_statusijintinggal
      this.visas.detail = detail
    },

    //  Pilih Visa Type for New Member
    // PilihVisaTypeMember(id_statusijintinggal, detail) {
    //   console.log('detail:' + detail)
    //   document.querySelector('#id_statusijintinggalmember').setAttribute('value', id_statusijintinggal)
    //   document.querySelector('#detailmember').setAttribute('value', detail)
    //   this.visas.member.id_statusijintinggal = id_statusijintinggal
    //   this.visas.detail = detail
    // },

    pilihNamaBelakang(id_member, nama_belakang) {
      document.querySelector('#namabelakangs').setAttribute('value', nama_belakang)
      this.visas.member.nama_belakang = nama_belakang
    },

    pilihNamaPerusahaan(id_member, nama_perusahaan) {
      document.querySelector('#perusahaans').setAttribute('value', nama_perusahaan)
      this.visas.member.nama_perusahaan = nama_perusahaan
    },

    PilihNegaraAsal(id_negara, nama_negara) {
      document.querySelector('#id_negara').setAttribute('value', id_negara)
      document.querySelector('#nama_negara').setAttribute('value', nama_negara)
      this.visas.member.negara_asal = id_negara
      this.visas.member.nama_negara = nama_negara
    },

    PilihKewarganegaraan(id_negara, nama_negara) {
      document.querySelector('#id_negara').setAttribute('value', id_negara)
      document.querySelector('#nama_kewarganegaraan').setAttribute('value', nama_negara)
      this.visas.member.kewarganegaraan = id_negara
      this.nama_kewarganegaraan = nama_negara
    },
  },
}
</script>
